function image($url, $w, $h) {
	window.open($url, "image", "menubar=0,toolbar=0,location=0,directories=0,status=0,resizable=0,scrollbars=0,width=" + ($w + 20) + ",height=" + ($h + 20));
}

function open_win(url, width, height) {
	defaultLeft = ((screen.width - width) / 2) + PositionX;
	defaultTop = ((screen.height - height) / 2) + PositionY;
	popupWin = window.open(url, 'obr', 'scrollbars=0,resizable=0,top=' + defaultTop + ',left=' + defaultLeft + ',menubar=0,width=' + width + ',height=' + height);
}

function open_full(oURL) {
	var left = 0;
	var top = 0;
	var sirka = screen.width;
	var vyska = screen.height;
	window.open(oURL, null, 'toolbar=no, menubar=no, location=no, directories=no, scrollbars=no, resizable=yes, status=no, width=' + sirka + ', height=' + vyska + ', top=' + top + ', left=' + left + '');
}

// funkce Validate plus konstruktory prvku formulare
function MeText(strName, fAllowEmpty) {
	this.strName = strName;
	this.fAllowEmpty = fAllowEmpty;
	this.Validate = function (field) {
		if ((!this.fAllowEmpty) && (field.value == "")) {
			alert('Vyplňte prosím "' + this.strName + '".');
			field.focus();
			return false;
		}
		return true;
	}
}

function autoLoadPic() {
	var d = document;
	if (d.images) {
		if (!d.loadPicl) d.loadPicl = new Array();
		var n, y = d.loadPicl.length, args = autoLoadPic.arguments;
		for (n = 0; n < args.length; n++)
			if (args[n].indexOf("#") != 0) {
				d.loadPicl[n] = new Image;
				d.loadPicl[y++].src = args[n];
			}
	}
}

// 
function MeRadio(strName, iFirstIndex) {
	this.strName = strName;
	this.iFirstIndex = iFirstIndex;
	this.Validate = function (field) {
		if (field.checked == false) {
			alert('Vyberte prosím "' + this.strName + '".');
			field.focus();
			return false;
		}
		return true;
	}
}

// schvali pokud je hodnota selectu vetsi nez iFirstIndex
function MeSelect(strName, iFirstIndex) {
	this.strName = strName;
	this.iFirstIndex = iFirstIndex;
	this.Validate = function (field) {
		if (field.selectedIndex < this.iFirstIndex) {
			alert('Vyberte prosím "' + this.strName + '".');
			field.focus();
			return false;
		}
		return true;
	}
}

function Validate(theForm, arr) {
	// radio check
	if ((document.cartReload.ftransType[0].checked == false)
		&& (document.cartReload.ftransType[1].checked == false)
		&& (document.cartReload.ftransType[2].checked == false)
		&& (document.cartReload.ftransType[3].checked == false)) {
		alert("Zvolte prosím způsob dopravy.");
		return false;
	}
	//

	for (var i = 0; i < theForm.length; i++) {
		if (arr[i]) {
			if (!arr[i].Validate(theForm.elements[i]))
				return false;
		}
	}
	return true;
}

function ValidateForm(theForm, arr) {
	for (var i = 0; i < theForm.length; i++) {
		if (arr[i]) {
			if (!arr[i].Validate(theForm.elements[i]))
				return false;
		}
	}
	return true;
}

function sumaReload() {
	var suma_bez = document.getElementById('suma_bez');
	var suma_s = document.getElementById('suma_s');

	suma_bez.innerHTML = zaokrouhliNaHalireJS(priceZbozi_bez + priceTrans_bez) + ' ,-Kč';

	cartReload.fSumaCB.value = zaokrouhliNaHalireJS(priceZbozi_bez + priceTrans_bez) + ' ,-Kč';
}


function selectTrans(nazev, price_bez, price_s) {
	var trans_nazev = document.getElementById('trans_nazev');
	var trans_price_bez = document.getElementById('trans_price_bez');
	var trans_price_s = document.getElementById('trans_price_s');

	priceTrans_bez = price_bez;
	priceTrans_s = price_s;

	trans_nazev.innerHTML = nazev;
	trans_price_bez.innerHTML = (price_bez) + ' ,-Kč';

	cartReload.ftransNazev.value = nazev;
	cartReload.ftransCB.value = (price_bez) + ' ,-Kč';

	// radio check - prenos hodnoty do druheho formu
	for (var i = 0; i <= 3; i++) {
		if (document.cartReload.ftransType[i].checked) document.cartSend.ftransType.value = (i + 1);
	}
	//
	sumaReload();
}


function zaokrouhliNaHalireJS(price) {
	price = price * 100;
	price = Math.round(price);
	price = price / 100;

	var rounded = Math.round(price);
	var rounded10 = Math.round(price * 10);
	var price10 = price * 10;

	if (rounded == price)
		priceStr = price.toString() + '.00';
	else {
		if (rounded10 == price10) {
			priceTempStr = rounded10.toString();
			priceTemp = priceTempStr.substring(-1, 0);
			priceTempVal = parseInt(priceTemp, 10);
			if (priceTempVal < 5)
				priceStr = rounded.toString() + '.00';
			else if (priceTempVal > 5)
				priceStr = rounded.toString() + '.50';
			else
				priceStr = price.toString() + '0';
		}
		else
			priceStr = rounded.toString() + '.00';
	}
	return priceStr;
}

function moneyFormat(value) {
	if (value === null || value === undefined) { return 0; }
	else {
		var result = value.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
		return result;
	}
}

function getLongCookie(cookie, index) {
	if (cookie === undefined) { return undefined; }
	else {
		var tmpArr_src = cookie ? cookie.split('|') : new Array();
		if (tmpArr_src[index] === undefined) {
			return undefined;
		}
		else {
			return tmpArr_src[index];
		}
	}

}

function setLongCookieValue(cookie, value, index) {
	//	if(cookie === undefined) { return false; } 
	//	else {
	//		console.log(cookie);
	var tmpArr_src = cookie ? cookie.split('|') : new Array();
	tmpArr_src[index] = value;
	var tmpStr_src = tmpArr_src.join('|');
	return tmpStr_src;
	//		return null;
	//	}
}

$(document).ajaxStart(function () {
	$('#loading').fadeIn();
}).ajaxStop(function () {
	$('#loading').fadeOut();
});

// Show Loading if bnt clicked but in #params, .bootstrap-touchspin-down, .btn-obj . Submitted forms hide Loading
$(document).ready(function () {

	// jquery.validate defaults on call .valid() method
	jQuery.validator.setDefaults({
		ignore: ".ignore"
	});


	$("button.btn, input[type=button].btn, input[type=submit].btn").click(function (e) {
		if ($(this).parents("#params").length == 1 || $(this).is(".bootstrap-touchspin-down") || $(this).is(".btn-obj") || $(this).is(".btn-reg") || $(this).is(".btn-modal")) {
		}
		else {
			$('#loading').fadeIn();
			if ($(this).closest('form').valid()) {
			}
			else {
				$('#loading').fadeOut();
			}
		}
	});
	$("a.btn").click(function (e) {
		if ($(this).is(".btn-obj")) {
		}
		else {
			$('#loading').fadeIn();
		}
	});
});


$(document).ready(function () {

	// Lightbox
	// $('.colorbox, .galerie .image a, .galerie1 .image a').lightBox();

	// SEARCH AUTOCOMPLETE
	$('.autocomplete').devbridgeAutocomplete({
		serviceUrl: '/',
		params: {
			"function": "sa"
		},
		minChars: 2,
		deferRequestBy: 300, //300ms
		groupBy: 'cat',
		onSelect: function (suggestion) {
			location.href = suggestion.data.href;
			$('#loading').fadeIn();
		},
		//onHint: function (hint) {     
		//$('#autocomplete-ajax-x').val(hint);
		//},	    
	});
	$('.autocomplete-mob').devbridgeAutocomplete({
		serviceUrl: '/',
		params: {
			"function": "sa"
		},
		minChars: 2,
		groupBy: 'cat',
		deferRequestBy: 300, //300ms
		onSelect: function (suggestion) {
			location.href = suggestion.data.href;
			$('#loading').fadeIn();
		},
		//onHint: function (hint) {     
		//$('#autocomplete-ajax-x').val(hint);
		//},
	});
	// SEARCH AUTOCOMPLETE

});

$.fn.digits = function () {
	return this.each(function () {
		$(this).text($(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."));
	})
}
